<template>
  <div class="change-password">
    <!-- <transition name="effect__dim" appear> -->
    <div class="change-password__dim" @click="onClickClosePassword" />
    <!-- </transition> -->
    <!-- <transition name="effect__article" appear> -->
    <article class="change-password__article">
      <template v-if="isInitPassword">
        <section class="change-password__section">
          <div class="change-password__row">
            <div class="change-password__cell">
              <strong class="change-password__title">아이디</strong>
            </div>
            <div class="change-password__cell">
              <Input
                placeholder="아이디"
                :autocomplete="'off'"
                :isDisabled="true"
                :value="userId"
              />
            </div>
          </div>
          <div class="change-password__row">
            <div class="change-password__cell">
              <strong class="change-password__title">신규 비밀번호</strong>
            </div>
            <div class="change-password__cell">
              <Input
                type="password"
                placeholder="신규 비밀번호"
                :autocomplete="'new-password'"
                :value.sync="newPassword"
                :isError="isError"
                @onInput="onInputPassword"
              />
            </div>
          </div>
          <div class="small" style="color: red">
            <small>영문 대/소문자, 숫자 및 특수문자 포함 8자 이상, 20자 이하로 구성해야합니다.</small>
          </div>
        </section>
      </template>
      <template v-else>
        <section class="change-password__section">
          <div class="change-password__row">
            <div class="change-password__cell">
              <strong class="change-password__title">아이디</strong>
            </div>
            <div class="change-password__cell">
              <Input
                placeholder="아이디"
                :autocomplete="'off'"
                :value.sync="accountId"
                :isError="isError"
              />
            </div>
          </div>
          <div class="change-password__row">
            <div class="change-password__cell">
              <strong class="change-password__title">기존 비밀번호</strong>
            </div>
            <div class="change-password__cell">
              <Input
                type="password"
                placeholder="기존 비밀번호"
                :autocomplete="'off'"
                :value.sync="oldPassword"
                :isError="isError"
                @onInput="onInputPassword"
              />
            </div>
          </div>
          <div class="change-password__row">
            <div class="change-password__cell">
              <strong class="change-password__title">신규 비밀번호</strong>
            </div>
            <div class="change-password__cell">
              <Input
                type="password"
                placeholder="신규 비밀번호"
                :autocomplete="'new-password'"
                :value.sync="newPassword"
                :isError="isError"
                @onInput="onInputPassword"
              />
            </div>
          </div>
          <div class="change-password__row">
            <div class="change-password__cell">
              <strong class="change-password__title">비밀번호 확인</strong>
            </div>
            <div class="change-password__cell">
              <Input
                type="password"
                placeholder="비밀번호 확인"
                :autocomplete="'new-password'"
                :value.sync="checkPassword"
              />
            </div>
          </div>
          <div class="small" style="color: red">
            <small>영문 대/소문자, 숫자 및 특수문자 포함 8자 이상, 20자 이하로 구성해야합니다.</small>
          </div>
        </section>
      </template>

      <section class="change-password__section">
        <div class="">
          <button class="btn-change" :disabled="btnDisabled" @click="onClickPasswordChange">
            비밀번호 변경
          </button>
        </div>
      </section>
    </article>
    <!-- </transition> -->
  </div>
</template>

<script>
import LocalStorageManager from "@/LocalStorageManager";
import Input from "@/components/common/input/Input";
import ApiService from "@/services/ApiService";
// import apiPath from '@/services/apiPath';
import { getIsKorean } from "@/utils/stringUtils";
//import { getIsAdmin } from '@/models/RoleEnumModel';
//import { parseJwt } from '@/utils/jwtUtils';

export default {
  name: "LoginChangePw",
  components: {
    Input,
  },
  props: {
    userId: String,
    isInitPassword: Boolean,
  },
  data() {
    return {
      accountId: "",
      oldPassword: "",
      newPassword: "",
      checkPassword: "",
      isError: false,
      errorMessage: "",
      isWarn: false,
    };
  },
  computed: {
    btnDisabled() {
      let ret = true;
      if (this.isInitPassword) {
        ret = this.newPassword.length >= 8 ? false : true;
      } else {
        if (this.newPassword.length >= 8 && this.newPassword === this.checkPassword) {
          ret = false;
        } else {
          ret = true;
        }
      }

      return ret;
    },
  },
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {
    onClickClosePassword() {
      this.$emit("onClickClosePassword");
    },
    onClickPasswordChange() {
      this.onSubmit();
    },
    async onSubmit(e) {
      let path = ``;
      let obj = {};
      if (this.isInitPassword) {
        path = `${this.$apiPath.PASSWORD_CHANGE}/${this.userId}`;
        obj = {
          newPassword: this.newPassword,
        };
      } else {
        path = `${this.$apiPath.PASSWORD_CHANGE}/${this.accountId}`;
        obj = {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        };
      }
      const result = await await ApiService.shared.putData(path, obj);
      const { code, data, text } = result;

      if (code !== "200") {
        alert(text);
        return;
      }

      if (data) {
        alert("다시 로그인 하여 주세요.");
        LocalStorageManager.shared.clear();
        window.location.reload();
      }
    },

    onInputPassword(e) {
      const char = e.data;

      if (!char) return;

      const isTypingKorean = getIsKorean(char);

      if (isTypingKorean) {
        this.errorMessage = "한/영 키를 확인해주세요";
      }

      this.isWarn = isTypingKorean;

      // 타이핑 하는 순간 에러는 초기화.
      this.isError = false;
    },
  },
};
</script>

<style scoped lang="scss">
.btn-change {
  display: block;
  width: 100%;
  height: 60px;
  margin-top: 32px;
  border-radius: 2px;
  font-weight: 500;
  font-size: 18px;
  background-color: #222;
  color: #fff;
  cursor: pointer;
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
.change-password {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  &__dim {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
  &__article {
    position: relative;
    width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
  }
  &__section {
    display: table;
    width: 100%;
  }
  &__row {
    display: table-row;
  }
  &__cell {
    display: table-cell;
    padding: 10px 0;
    vertical-align: middle;
    & .tf_comm {
      margin-top: 0;
    }
  }
  &__title {
    display: block;
  }
}
.effect {
  $dimDuration: 0.2s;
  $articleDuration: 0.3s;
  @mixin fakeEffect {
    opacity: 1;
    transition: opacity 0.5s linear 0s;
  }
  &__password-enter-active,
  &__password-leave-active {
    @include fakeEffect;
  }
  &__password {
    &-enter {
      &-active {
        .change-password {
          &__dim {
            opacity: 0;
            transition: opacity $dimDuration ease-in 0s;
          }
          &__article {
            opacity: 0;
            transform: translateY(30px);
            transition: opacity $articleDuration ease-in 0.1s,
              transform $articleDuration ease-in 0.1s;
          }
        }
      }

      &-to {
        .change-password {
          &__dim {
            opacity: 1;
          }
          &__article {
            opacity: 1;
            transform: translateY(0px);
          }
        }
      }
    }
    &-leave {
      &-active {
        .change-password {
          &__dim {
            opacity: 1;
            transition: opacity $dimDuration ease-out 0.2s;
          }
          &__article {
            opacity: 1;
            transform: translateY(0px);
            transition: opacity $articleDuration ease-out 0s, transform $articleDuration ease-out 0s;
          }
        }
      }
      &-to {
        .change-password {
          &__dim {
            opacity: 0;
          }
          &__article {
            opacity: 0;
            transform: translateY(-50px);
          }
        }
      }
    }
  }
}
</style>
