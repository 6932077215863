<template>
  <div id="kakaoWrap" :class="['wrap_login', isBanner ? 'banner_on' : '']">
    <div id="kakaoHead" class="head_login" role="banner">
      <h1 class="tit_login">
        <!-- <a href="javascript:void(0);" id="kakaoServiceLogo" class="link_logo"></a> -->
        <img :src="logoPath" class="img_g" alt="구매시스템" />
      </h1>
      <div v-if="isBanner" :class="['banner_login', isBanner ? 'on' : '']">
        <p class="desc_banner">
          본 시스템은 Chrome 브라우저에 최적화 되어 있습니다. 안정적인 시스템 이용을 위하여 최적화된
          브라우저를 사용해주시기 바랍니다.
        </p>
        <button type="button" class="btn_close" @click="onClickCloseBnr">
          <span class="ico_purchase ico_cross2">닫기</span>
        </button>
      </div>
    </div>
    <hr class="hide" />
    <div id="kakaoContent" class="cont_login" role="main">
      <div id="mArticle">
        <div class="section_visual">
          <div class="inner_visual">
            <p class="desc_visual" v-html="descVisual" />
            <span class="bar_visual" />
            <strong class="tit_visual">전자구매시스템 - 내부직원용</strong>
          </div>
        </div>
        <div class="section_login">
          <div class="inner_login">
            <div class="area_login">
              <h2 class="screen_out">관리자시스템 로그인</h2>
              <template v-if="$isLIG">
                <!-- prod -->
                <form @submit="onSubmit">
                  <fieldset>
                    <Input
                      type="text"
                      placeholder="아이디를 입력해주세요."
                      :value.sync="userId"
                      :isError="isError"
                    />
                    <Input
                      type="password"
                      placeholder="비밀번호를 입력해주세요."
                      :value.sync="password"
                      :isError="isError"
                      @onInput="onInputPassword"
                    />

                    <p v-if="isError || isWarn" class="desc_error">
                      {{ errorMessage }}
                    </p>

                    <button
                      ref="btnSubmit"
                      type="submit"
                      class="btn_login"
                      :disabled="isDisabledButton"
                    >
                      로그인
                    </button>
                  </fieldset>
                </form>
                <div class="wrap-option">
                  <button class="btn-password__change" @click="onClickOpenPassword">
                    비밀번호 변경
                  </button>
                </div>
              </template>
            </div>

            <div class="area_notice">
              <h2 class="tit_notice">공지사항</h2>
              <template v-if="notices.length > 0">
                <ul class="list_notice">
                  <li v-for="item in notices" :key="item.seq">
                    <button
                      :class="[item.fixYn == 'Y' ? 'link_notice_bold' : 'link_notice']"
                      @click="onClickNotice(item)"
                    >
                      <span class="txt_date">{{ item.regData | dateStringFormat }}</span>
                      <strong
                        :class="[item.fixYn == 'Y' ? 'subject_notice_bold' : 'subject_notice']"
                        v-html="item.title"
                      />
                    </button>
                  </li>
                </ul>
              </template>
              <template v-else>
                <p class="desc_emtpy">공지사항이 없습니다.</p>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="effect__password" appear>
      <LoginChangePw
        v-if="isChangePassword"
        :userId="userId"
        :isInitPassword="isInitPassword"
        @onClickClosePassword="onClickClosePassword"
      />
    </transition>
    <NoticeDetailPopup
      v-if="isPopNoticeDetail"
      :dataList="noticeData"
      @onClickClose="onClickClosePopNotice"
    />
  </div>
</template>

<script>
import LocalStorageManager from "@/LocalStorageManager";
import Input from "@/components/common/input/Input";
import ApiService from "@/services/ApiService";

import { getIsKorean } from "@/utils/stringUtils";

import LoginChangePw from "@/pages/admin/LoginChangePw";
import NoticeDetailPopup from "@/components/user/login/popup/NoticeDetailPopup";

export default {
  name: "Login",
  components: {
    NoticeDetailPopup,
    LoginChangePw,
    Input,
  },
  data() {
    return {
      descVisual: process.env.VUE_APP_LOGIN_VISUAL_TEXT,
      copyright: process.env.VUE_APP_DOC_COPYRIGHT,
      notices: [],
      noticeData: {},

      logoPath: require(`${process.env.VUE_APP_ASSETS_PATH}/logo_en_dark.png`),
      userId: "",
      password: "",
      isError: false,
      errorMessage: "",
      isBanner: false,
      isWarn: false,

      isInitPassword: false,
      isChangePassword: false,
      isPopNoticeDetail: false,
    };
  },
  computed: {
    isDisabledButton() {
      if (!this.userId.trim()) return true;
      if (!this.password.trim()) return true;

      return false;
    },
    checkBrowser() {
      const agent = navigator.userAgent.toLowerCase();
      return agent.indexOf("chrome") > -1;
    },
  },
  created() {
    localStorage.clear();
    this.checkRefreshToken();
    this.initNoticeList();
  },
  beforeMount() {},
  mounted() {
    // const query = this.$route.query;
    // if(query.fromApproval && query.token ) {
    //   sessionStorage.setItem( 'userData', query.token );
    //   this.$router.push({
    //     path: this.$routerPath.PURCHASE_REQUEST_LIST,
    //     // path: this.$routerPath.HOME
    //   });
    // }

    this.$nextTick(() => {
      if (this.checkBrowser) return;
      setTimeout(() => {
        this.isBanner = true;
      }, 500);
    });
  },
  methods: {
    onClickCloseBnr() {
      this.isBanner = false;
    },
    onSubmit(e) {
      e.preventDefault();

      const objSend = {
        loginType: "manager",
        username: this.userId,
        password: this.password,
      };

      this.postLogin(objSend);
    },
    casLogin() {
      document.loginForm.submit();
    },
    checkRefreshToken() {
      if (!this.$isProd && this.$route.query.refreshToken) {
        this.$router
          .push({
            path: this.$routerPath.CAS_LOGIN_SUCCESS,
            query: { refreshToken: this.$route.query.refreshToken },
          })
          .catch(() => {});
      }
    },

    async postLogin(obj) {
      const result = await ApiService.shared.login(obj);
      if (result.code !== "200") {
        this.errorMessage = result.text;
        this.isError = true;

        const btn = this.$refs.btnSubmit;
        if (document.activeElement !== btn) {
          btn.focus();
        }
        LocalStorageManager.shared.clear();
        return;
      }
      if (result.code === "200" && result.text === "PASSWORD_INIT") {
        this.initPassword();
        return;
      }

      this.isError = false;

      sessionStorage.clear();
      LocalStorageManager.shared.setUserData(result.data);

      const passwordCheckPath = this.$apiPath.PASSWORD_CHANGE;
      const passwordCheckResult = await ApiService.shared.getData(
        `${passwordCheckPath}/m/${this.userId}`,
      );
      if (passwordCheckResult.data) {
        alert("비밀번호 변경기한이 지났습니다.\n비밀번호를 변경하하셔야됩니다.");
        this.isChangePassword = true;
        return;
      }

      const toPath = this.$route.query.redirect;
      await this.$router.push({
        path: toPath ? toPath : this.$routerPath.HOME,
        // path: this.$routerPath.HOME
      });
    },
    initPassword() {
      this.isInitPassword = true;
      this.isChangePassword = true;
    },
    onInputPassword(e) {
      const char = e.data;

      if (!char) return;

      const isTypingKorean = getIsKorean(char);

      if (isTypingKorean) {
        this.errorMessage = "한/영 키를 확인해주세요";
      }

      this.isWarn = isTypingKorean;

      // 타이핑 하는 순간 에러는 초기화.
      this.isError = false;
    },
    onClickOpenPassword() {
      this.isChangePassword = true;
    },
    onClickClosePassword() {
      this.isChangePassword = false;
    },

    async initNoticeList() {
      const path = `${this.$apiPath.NOTICE_MAIN}`;
      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;
      if (code !== "200") {
        alert(`${code}_${text}`);
        return;
      }
      this.notices = data;
    },
    onClickNotice(item) {
      this.isPopNoticeDetail = true;
      this.getNoticesDetail(item.seq);
    },
    async getNoticesDetail(seq) {
      const path = `${this.$apiPath.NOTICE_MAIN}/${seq}`;
      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;
      if (code !== "200") {
        alert(text);
        return;
      }
      this.noticeData = data;
    },
    onClickClosePopNotice() {
      this.isPopNoticeDetail = false;
      this.noticeData = {};
    },
  },
};
</script>
<style src="@/assets/css/common.css"></style>

<style scoped lang="scss">
#app > div {
  height: 100%;
}
.wrap_login {
  position: relative;
  min-width: 1440px;
  box-sizing: border-box;
  transition: padding 0.1s ease-in-out 0.1s;
}
.wrap_login.banner_on {
  padding: 56px 0 0;
}
.wrap_login,
.wrap_login .cont_login {
  height: 100%;
  min-height: 866px;
}
.wrap_login .cont_login {
  position: relative;
  background-color: #fed14b;
}
.wrap_login #mArticle {
  overflow: hidden;
  min-width: 1290px;
  height: 100%;
}
/* head */
.head_login .tit_login {
  position: absolute;
  left: 0;
  padding: 40px 70px 0;
  z-index: 10;
  font-size: 18px;
  line-height: 18px;
  color: #222;
}
/* .head_login .tit_login .link_logo{display:block} */
.head_login .tit_login .img_g {
  width: 207px;
}
/* foot */
.foot_login .txt_copy {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 70px 40px;
  z-index: 10;
  font-size: 11px;
  line-height: 16px;
  color: #555;
}
/* -- 좌측 영역 -- */
.section_visual {
  float: left;
  width: 61%;
  height: 100%;
  background: url("~@/assets/images/bg_login_r.png") no-repeat 100% 0,
    url("~@/assets/images/bg_login_l.png") no-repeat 0 100%;
  background-size: 478px 780px, 266px 190px;
  font-size: 48px;

  color: #222222;
  white-space: nowrap;
}
.section_visual:after {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: "";
}
.section_visual .inner_visual {
  display: inline-block;
  padding: 100px 0 141px 70px;
  vertical-align: middle;
}
.section_visual .desc_visual {
  font-weight: 100;
}
.section_visual .bar_visual {
  display: block;
  width: 96px;
  height: 2px;
  margin-top: 24px;
  background-color: rgba(255, 240, 179, 0.9);
}
.section_visual .tit_visual {
  display: block;
  margin-top: 20px;
  font-weight: 500;
  font-size: 38px;

  color: #222;
}

.section_visual .link_join {
  display: block;
  width: 133px;
  height: 46px;
  margin-top: 40px;
  padding: 0 20px;
  border-radius: 2px;
  border: 1px solid #555;
  font-size: 14px;
  line-height: 49px;
  color: #222;
  text-align: left;
}
.section_visual .link_join .ico_arr_join {
  float: right;
  margin-top: 19px;
}
/* -- 우측영역 -- */
.section_login {
  overflow: hidden;
  width: 39%;
  height: 100%;
  background-color: #fff;
  font-size: 0;
  white-space: nowrap;
}
.section_login:after {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: "";
}
.section_login .inner_login {
  display: inline-block;
  width: 100%;
  padding: 44px 16% 40px 10%;
  box-sizing: border-box;
  vertical-align: middle;
  white-space: normal;
}

/* 로그인 */
.area_login::v-deep .select_comm .el-select {
  display: block;
  width: 278px;
  height: 25px;
}
.area_login::v-deep .select_comm .el-select:after {
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 31px;
  height: 8px;
  background-color: #fff0b2;
  content: "";
}
.area_login::v-deep .select_comm .el-select > .el-input {
  z-index: 10;
  background: none;
}
.area_login::v-deep .select_comm .el-input__inner {
  padding: 0 38px 0 0;
  border: 0 none;
  font-weight: 500;
  font-size: 20px;
  background: none;
  color: #222;
}
.area_login .select_comm + .tf_comm {
  margin-top: 26px;
}
.area_login .tf_comm {
  height: 52px;
}
.area_login .tf_comm::v-deep input {
  padding: 0 16px;
  font-size: 14px;
}
.area_login .tf_comm + .tf_comm {
  margin-top: 8px;
}
.area_login .desc_error {
  margin-bottom: -6px;
  padding-top: 6px;
  font-size: 12px;
  line-height: 16px;
  color: #e93b3b;
}
.area_login .btn_login {
  width: 100%;
  height: 60px;
  margin-top: 12px;
  border-radius: 2px;
  font-size: 18px;
  line-height: 65px;
  background-color: #555;
  color: #fff;
  &:disabled {
    cursor: auto;
  }
}
.area_login .util_login {
  overflow: hidden;
  margin-top: 10px;
  font-size: 0;
  text-align: right;
}
.area_login .util_login .check_comm {
  float: left;
}
.area_login .util_login .check_comm::v-deep label {
  font-size: 14px;
  line-height: 20px;
}
.area_login .util_login .check_comm::v-deep .ico_checkbox {
  top: 2px;
  margin-top: 0;
}
.area_login .util_login .link_util {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: #555;
  vertical-align: top;
}
.area_login .util_login .bar_util {
  display: inline-block;
  width: 1px;
  height: 10px;
  margin: 5px 8px;
  background-color: #d8d8d8;
  content: "";
}
/* 공지사항 */
.area_notice {
  padding-top: 51px;
}
/* 공지 목록 */
.area_notice .tit_notice {
  display: inline-block;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
  color: #222;
  vertical-align: top;
}
.area_notice .list_notice {
  margin-top: 26px;
}
.area_notice .list_notice li {
  height: 20px;
}
.area_notice .list_notice li + li {
  margin-top: 8px;
}
.area_notice .list_notice .link_notice {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 20px;
  text-align: left;
}
.area_notice .list_notice .link_notice:hover .subject_notice {
  color: #222;
  text-decoration: underline;
}
.area_notice .list_notice .link_notice_bold {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 20px;
  text-align: left;
  font-weight: bold;
}
.area_notice .list_notice .link_notice_bold:hover .subject_notice {
  color: #222;
  text-decoration: underline;
}
.area_notice .list_notice .badge_notice {
  float: left;
  width: 37px;
  height: 18px;
  margin-right: 11px;
  border-radius: 2px;
  border: 1px solid #d8d8d8;
  font-size: 12px;
  line-height: 20px;
  color: #222;
  text-align: center;
}
.area_notice .list_notice .subject_notice {
  display: block;
  overflow: hidden;
  margin-right: 128px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #555;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.area_notice .list_notice .subject_notice_bold {
  display: block;
  overflow: hidden;
  margin-right: 128px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #555;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
}
.area_notice .list_notice .txt_date {
  float: right;
  font-size: 14px;
  line-height: 24px;
  color: #888;
}
.area_notice .desc_emtpy {
  padding: 42px 0;
  font-size: 14px;
  line-height: 20px;
  color: #bbb;
  text-align: center;
}

/*




body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans KR', AppleSDGothicNeo-Regular, 'Malgun Gothic', '맑은 고딕', sans-serif;
}
fieldset {
  border: none;
  padding: 0;
}
.wrap_login_admin {
  box-sizing: border-box;
  height: 100vh;
  text-align: center;
  transition: padding 0.1s ease-in-out 0.1s;

  fieldset {
    position: relative;
  }
}
.wrap_login_admin.banner_on {
  padding: 56px 0 0;
}
.wrap_login_admin:after {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.wrap_login_admin .cont_login_admin {
  display: inline-block;
  width: 400px;
  padding-bottom: 24px;
  vertical-align: middle;
  white-space: normal;
}

.wrap_login_admin .tit_login .img_g {
  margin: 0 auto;
  width: 414px;
}
.wrap_login_admin .tf_comm {
  height: 52px;
  margin-top: 24px;
}
.wrap_login_admin .tf_comm ::v-deep input {
  padding: 0 16px;
  font-size: 14px;
}
.wrap_login_admin .tf_comm + .tf_comm {
  margin-top: 8px;
}

.desc_error {
  position: absolute;
  left: 0;
  bottom: 64px;
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #e93b3b;
  text-align: left;
}
.wrap_login_admin .btn_login {
  display: block;
  width: 100%;
  height: 60px;
  margin-top: 32px;
  border-radius: 2px;
  border: none;
  background-color: #222;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}


.banner_login {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 55px;
  border-bottom: 1px solid #ffe67f;
  background-color: #fffbed;
  text-align: left;
}

.banner_login .desc_banner {
  float: left;
  margin: 0;
  padding: 17px 70px;
  font-size: 14px;
  line-height: 22px;
  color: #888;
}
.banner_login .btn_close {
  float: right;
  padding: 16px 70px;
  outline: none;
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
  color: #888888;
  font-size: 14px;
  line-height: 22px;
}

.wrap-option {
  padding-top: 5px;
  text-align: right;
}
.btn-password__change {
  border: none;
  background: none;
  color: #888888;
  cursor: pointer;
}
*/
</style>
